body {
  max-width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: FMAba-Bold;
  src: url("../fonts/pasadika/FM-Aba-Bold.TTF");
  font-display: swap;
}

@font-face {
  font-family: FMAbhaya;
  src: url("../fonts/pasadika/FM-Abhaya.TTF");
  font-display: swap;
}

@font-face {
  font-family: FMArjunn;
  src: url("../fonts/pasadika/FM-Arjunn.TTF");
  font-display: swap;
}

@font-face {
  font-family: FMBasuru;
  src: url("../fonts/pasadika/FM-Basuru.TTF");
  font-display: swap;
}

@font-face {
  font-family: FMBindumathi;
  src: url("../fonts/pasadika/FM-Bindumathi.TTF");
  font-display: swap;
}

@font-face {
  font-family: FMDerana;
  src: url("../fonts/pasadika/FM-Derana.TTF");
  font-display: swap;
}

@font-face {
  font-family: FMEb;
  src: url("../fonts/pasadika/FM-eb.TTF");
  font-display: swap;
}

@font-face {
  font-family: FMEmanee;
  src: url("../fonts/pasadika/FM-Emanee.TTF");
  font-display: swap;
}

@font-face {
  font-family: FMGanganee;
  src: url("../fonts/pasadika/FM-Ganganee.TTF");
  font-display: swap;
}

@font-face {
  font-family: FMGangani;
  src: url("../fonts/pasadika/FM-Gangani.TTF");
  font-display: swap;
}

@font-face {
  font-family: FMGemunu;
  src: url("../fonts/pasadika/FM-Gemunu.TTF");
  font-display: swap;
}

@font-face {
  font-family: FMMalithi;
  src: url("../fonts/pasadika/FM-Malithi.TTF");
  font-display: swap;
}

@font-face {
  font-family: FMRajantha;
  src: url("../fonts/pasadika/FM-Rajantha.TTF");
  font-display: swap;
}

@font-face {
  font-family: FMSamantha;
  src: url("../fonts/pasadika/FM-Samantha.TTF");
  font-display: swap;
}

@font-face {
  font-family: AMSHiru;
  src: url("../fonts/pasadika/AMS Hiru.ttf");
  font-display: swap;
}

@font-face {
  font-family: Apex-13;
  src: url("../fonts/pasadika/apex-a.pura-013.ttf");
  font-display: swap;
}

@font-face {
  font-family: Apex-15;
  src: url("../fonts/pasadika/apex-a.pura-015.ttf");
  font-display: swap;
}

@font-face {
  font-family: SaraBaron;
  src: url("../fonts/pasadika/SARA-BARON.TTF");
  font-display: swap;
}

@font-face {
  font-family: Cannon;
  src: url("../fonts/english/CANON.ttf");
  font-display: swap;
}

@font-face {
  font-family: Oswald;
  src: url("../fonts/english/Oswald.ttf");
  font-display: swap;
}

@font-face {
  font-family: IMFellEnglish;
  src: url("../fonts/english/IMFellEnglish.ttf");
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
