.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
    border-radius: 5px;
}

.nav-menu > ul {
    display: flex;
    border-radius: 5px;
    font-family: FM-Emanee, sans-serif;
}

.nav-menu > ul > li {
    position: relative;
    white-space: nowrap;
    padding: 18px 15px;
    margin-left: 5px;
    transition: 0.5s;
    border-radius: 5px;
}

.nav-menu a {
    display: block;
    position: relative;
    color: #fff;
    padding: 18px 15px;
    transition: 0.5s;
    font-size: 1.2rem;
    font-weight: 100;
    text-decoration: none;
    font-family: "FMSamantha", sans-serif;
}

.nav-menu p {
    display: block;
    position: relative;
    color: #535353;
    transition: 0.3s;
    font-size: 1.2rem;
    font-weight: 100;
    text-decoration: none;
    font-family: "FMMalithi", sans-serif;
}

.nav-menu > ul > li:hover, .nav-menu .active {
    background: #346734;
}

.nav-menu .drop-down ul {
    display: block;
    position: absolute;
    right: 0;
    top: calc(100% + 30px);
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    padding: 0;
    background: #fffcee;
    box-shadow: 0 0 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
}

.nav-menu .drop-down:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.nav-menu .drop-down li {
    min-width: 180px;
    position: relative;
}

.nav-menu .drop-down ul a {
    padding: 15px 20px;
    text-transform: none;
    font-family: "FMMalithi", sans-serif;
    color: #191919;
}

.nav-menu .drop-down ul a:hover, .nav-menu .drop-down ul .active > a, .nav-menu .drop-down ul li:hover > a {
    color: #000000;
    background-color: #F2EDE4;
}


.nav-menu .drop-down .drop-down ul {
    top: 0;
    right: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
    opacity: 1;
    top: 0;
    right: 100%;
}

.nav-menu .drop-down .drop-down > a {
    padding-right: 35px;
}

@media (max-width: 1366px) {
    .nav-menu .drop-down .drop-down ul {
        right: -90%;
    }

    .nav-menu .drop-down .drop-down:hover > ul {
        right: -100%;
    }
}

.highlighted-text {
    font-size: 1.6rem;
    font-family: FMBasuru;
}

.highlighted-text2 {
    font-size: 1.3rem;
    font-family: FMMalithi;
    font-weight: bold;
}

@property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}

.animated-card-border {
    margin: 0 auto;
    border-radius: 10px;
    border: 2px solid #fff5d2;
    position: relative;
    z-index: 1 !important;
}

.animated-card-border::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    padding: 2px;
    border-radius: 10px;
    z-index: -1;
}

.animated-card-border::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    background-image: conic-gradient(from var(--angle),
    #fff9d6,
    #d8ffbc,
    #d1ffff,
    #ffd1d1,
    #beffe5,
    #ffe6af);
    translate: -50% -50%;
    padding: 4px;
    border-radius: 10px;
    z-index: -1;
}

.animated-card-border:hover::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    padding: 4px;
    border-radius: 10px;
    z-index: -1;
    animation: 5s spin linear infinite;
    background-image: conic-gradient(from var(--angle),
    #fff9d6,
    #d8ffbc,
    #d1ffff,
    #ffd1d1,
    #beffe5,
    #ffe6af);
}

@keyframes spin {
    from {
        --angle: 0deg;
    }
    to {
        --angle: 360deg;
    }
}

.preloader {
    animation: fadeinout 9s linear forwards;
    opacity: 1;
}

@-webkit-keyframes fadeinout {
    14% { opacity: 1; }
    86% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes fadeinout {
    14% { opacity: 1; }
    86% { opacity: 1; }
    100% { opacity: 0; }
}